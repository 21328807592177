// src/components/Header.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Token löschen
    navigate('/'); // Zur AuthPage navigieren
    window.location.reload(); // Seite neu laden, um Zustand zu aktualisieren
  };

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white font-bold text-lg">
          Rollenspiel App
        </div>
        <div>
          <Link to="/dashboard" className="text-white mx-4 hover:underline">
            Dashboard
          </Link>
          <Link to="/create-character" className="text-white mx-4 hover:underline">
            Charakter anlegen
          </Link>
          <Link to="/my-characters" className="text-white mx-4 hover:underline">
            Meine Charaktere
          </Link>
		  <Link to="/force-abilities" className="text-white mx-4 hover:underline">
            Fähigkeiten
          </Link>
          <button onClick={handleLogout} className="bg-red-500 text-white px-4 py-2 rounded ml-4">
            Logout
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Header;