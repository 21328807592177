import React from 'react';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const navigate = useNavigate();

  const handleCreateChar = () => {
    navigate('/create-character'); // Leitet zur Charaktererstellung
  };

  const handleViewChars = () => {
    navigate('/my-characters'); // Leitet zur Liste der Charaktere
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-800 text-white">
      <h1 className="text-4xl font-bold mb-8">Willkommen im Dashboard</h1>
      <button
        onClick={handleCreateChar}
        className="bg-gradient-to-r from-red-500 via-orange-500 via-yellow-500 via-green-500 via-blue-500 via-indigo-500 to-purple-500 text-white py-2 px-4 rounded mb-4 hover:bg-purple-700"
      >
        Charakter anlegen
      </button>
      <button
        onClick={handleViewChars}
        className="bg-gradient-to-r from-red-500 via-orange-500 via-yellow-500 via-green-500 via-blue-500 via-indigo-500 to-purple-500 text-white py-2 px-4 rounded hover:bg-purple-700"
      >
        Meine Chars
      </button>
    </div>
  );
}

export default Dashboard;