import React, { useEffect, useState } from 'react';
import axios from 'axios';

function MyCharacters() {
  const [characters, setCharacters] = useState([]);
  const [selectedCharacter, setSelectedCharacter] = useState(null);

  // Bereits zugewiesene Fähigkeiten
  const [assignedAbilities, setAssignedAbilities] = useState([]);
  // Noch (teilweise) wählbare Fähigkeiten
  const [availableAbilities, setAvailableAbilities] = useState([]);

  // Klasse, Kategorie, Fähigkeit, Stufe (für Zuweisung)
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedAbility, setSelectedAbility] = useState("");
  const [selectedStage, setSelectedStage] = useState("");

  // Mögliche Stufen (1..5) je Fähigkeit
  const [abilityStages, setAbilityStages] = useState([]);

  // stageCounts: abilityId => totalStages (z.B. 5)
  const [stageCounts, setStageCounts] = useState({});

  const userId = localStorage.getItem('userId');

  // ─────────────────────────────────────────────
  // 1) Charaktere laden
  // ─────────────────────────────────────────────
  useEffect(() => {
    if (!userId) {
      console.error("User-ID nicht gefunden");
      return;
    }

    const fetchCharacters = async () => {
      try {
        const response = await axios.get(
          `http://localhost:5000/characters/user/${userId}`
        );
        if (response.status === 200) {
          setCharacters(response.data);
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Charaktere:", error);
      }
    };

    fetchCharacters();
  }, [userId]);

  // ─────────────────────────────────────────────
  // 2) StageCounts einmalig laden
  // ─────────────────────────────────────────────
  useEffect(() => {
    const fetchStageCounts = async () => {
      try {
        const res = await axios.get('http://localhost:5000/api/abilities/stage-counts');
        // Ergebnis: [{ ability_id: 6, totalStages: 5 }, ... ]
        const map = {};
        res.data.forEach((item) => {
          map[item.ability_id] = item.totalStages;
        });
        setStageCounts(map);
      } catch (err) {
        console.error("Fehler beim Laden von stageCounts:", err);
      }
    };
    fetchStageCounts();
  }, []);

  // ─────────────────────────────────────────────
  // 3) Wenn Charakter gewählt => Abilities laden
  // ─────────────────────────────────────────────
  useEffect(() => {
    if (selectedCharacter) {
      fetchAssignedAndAvailable(selectedCharacter.id);
    }
  }, [selectedCharacter]);

  const fetchAssignedAndAvailable = async (characterId) => {
    try {
      // (A) Bereits zugewiesene Fähigkeiten
      const assignedRes = await axios.get(`http://localhost:5000/api/abilities/assigned/${characterId}`);
      const assignedData = assignedRes.data;
      setAssignedAbilities(assignedData);

      // (B) Alle Fähigkeiten
      const allRes = await axios.get(`http://localhost:5000/api/abilities/force-abilities`);
      const allData = allRes.data; // [{ id, name, ... }, ...]

      // Baue Map: abilityId => Set(assignedStages)
      // Damit wissen wir pro Ability, welche Stufen bereits vergeben sind
      const assignedMap = {};
      assignedData.forEach((entry) => {
        if (!assignedMap[entry.abilityId]) {
          assignedMap[entry.abilityId] = new Set();
        }
        assignedMap[entry.abilityId].add(entry.assignedStage);
      });

      // Nun filtern wir 'allData' so, dass eine Ability nur
      // verschwindet, wenn "assignedStages >= totalStages".
      // stageCounts[fa.id] sagt uns: wie viele Stufen hat diese Ability insgesamt?
      const filtered = allData.filter((fa) => {
        const assignedCount = assignedMap[fa.id] ? assignedMap[fa.id].size : 0;
        const totalStages = stageCounts[fa.id] || 5; // Fallback 1, falls unbekannt
        // Solange assignedCount < totalStages, bleibt ability noch "verfügbar"
        return assignedCount < totalStages;
      });

      setAvailableAbilities(filtered);
    } catch (error) {
      console.error("Fehler beim Laden der Fähigkeiten:", error);
    }
  };

  // ─────────────────────────────────────────────
  // 4) Stufen laden (wenn Ability gewählt)
  // ─────────────────────────────────────────────
  useEffect(() => {
    if (selectedAbility) {
      axios
        .get(`http://localhost:5000/force_abilities/${selectedAbility}/stages`)
        .then((res) => {
          setAbilityStages(res.data);
        })
        .catch((err) => {
          console.error("Fehler beim Laden der Stufen:", err);
        });
    } else {
      setAbilityStages([]);
    }
  }, [selectedAbility]);

  // ─────────────────────────────────────────────
  // 5) Neue Fähigkeit (Stufe X) zuweisen
  // ─────────────────────────────────────────────
  const handleAddAbility = async () => {
    if (!selectedCharacter || !selectedAbility || !selectedStage) {
      alert("Bitte Charakter, Fähigkeit und Stufe auswählen.");
      return;
    }
    try {
      await axios.post("http://localhost:5000/api/abilities/assign", {
        characterId: selectedCharacter.id,
        abilityId: selectedAbility,
        stage: selectedStage,
      });

      // Neu laden => assignedAbilities + availableAbilities updaten
      await fetchAssignedAndAvailable(selectedCharacter.id);

      // Reset
      setSelectedClass("");
      setSelectedType("");
      setSelectedAbility("");
      setSelectedStage("");
      alert("Fähigkeit erfolgreich zugewiesen!");
    } catch (err) {
      console.error("Fehler beim Zuweisen der Fähigkeit:", err);
      alert("Ups, da lief etwas schief.");
    }
  };

  // ─────────────────────────────────────────────
  // UI Rendering
  // ─────────────────────────────────────────────
  return (
    <div className="container mx-auto mt-10 bg-gray-900 p-6 rounded-lg text-white">
      <h2 className="text-xl font-bold mb-4">Meine Charaktere</h2>

      <table className="min-w-full bg-gray-800 text-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-700">Name</th>
            <th className="py-2 px-4 border-b border-gray-700">Vorname</th>
            <th className="py-2 px-4 border-b border-gray-700">Geburtsdatum</th>
            <th className="py-2 px-4 border-b border-gray-700">Geburtsplanet</th>
            <th className="py-2 px-4 border-b border-gray-700">Einheit</th>
            <th className="py-2 px-4 border-b border-gray-700">Posten</th>
            <th className="py-2 px-4 border-b border-gray-700">Rang</th>
            <th className="py-2 px-4 border-b border-gray-700">Aktion</th>
          </tr>
        </thead>
        <tbody>
          {characters.map((char) => (
            <tr key={char.id}>
              <td className="py-2 px-4 border-b border-gray-700">{char.name}</td>
              <td className="py-2 px-4 border-b border-gray-700">{char.first_name}</td>
              <td className="py-2 px-4 border-b border-gray-700">{char.birth_date}</td>
              <td className="py-2 px-4 border-b border-gray-700">{char.birth_planet}</td>
              <td className="py-2 px-4 border-b border-gray-700">{char.unit}</td>
              <td className="py-2 px-4 border-b border-gray-700">{char.position}</td>
              <td className="py-2 px-4 border-b border-gray-700">{char.rank}</td>
              <td className="py-2 px-4 border-b border-gray-700">
                <button
                  onClick={() => setSelectedCharacter(char)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
                >
                  Fähigkeiten anzeigen
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Bereits zugewiesene Fähigkeiten + Formular zum Hinzufügen */}
      {selectedCharacter && (
        <div className="mt-6">
          <h3 className="text-lg font-semibold">
            Fähigkeiten von {selectedCharacter.name}:
          </h3>
          <ul className="list-disc list-inside">
            {assignedAbilities.length > 0 ? (
              assignedAbilities.map((ability) => (
                <li key={ability.abilityId}>
                  <strong>{ability.abilityName}</strong>{" "}
                  (Stufe {ability.assignedStage})<br />
                  <em>{ability.baseAbilityDescription}</em>
                  {ability.stageDescription && (
                    <p className="text-gray-400">
                      ⇒ {ability.stageDescription}
                    </p>
                  )}
                </li>
              ))
            ) : (
              <p>Dieser Charakter hat noch keine Fähigkeiten.</p>
            )}
          </ul>

          {/* Formular zum Zuweisen einer neuen Fähigkeit mit spezieller Stufe */}
          <div className="mt-4">
            <h3 className="text-lg font-semibold">Fähigkeit zuweisen:</h3>

            {/* 1) Klasse */}
            <select
              value={selectedClass}
              onChange={(e) => setSelectedClass(e.target.value)}
              className="bg-gray-700 text-white p-2 rounded-md"
            >
              <option value="">Wähle eine Klasse</option>
              {[...new Set(availableAbilities.map((a) => a.class_id))]
                .filter((cls) => cls !== null && cls !== undefined)
                .map((cls) => (
                  <option key={cls} value={cls}>
                    {cls}
                  </option>
                ))}
            </select>

            {/* 2) Kategorie */}
            {selectedClass && (
              <select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                className="bg-gray-700 text-white p-2 rounded-md ml-2"
              >
                <option value="">Wähle Typ</option>
                <option value="Grundfähigkeit">Grundfähigkeit</option>
                <option value="Spezialfähigkeit">Spezialfähigkeit</option>
              </select>
            )}

            {/* 3) Fähigkeit */}
            {selectedType && selectedClass && (
              <select
                value={selectedAbility}
                onChange={(e) => setSelectedAbility(e.target.value)}
                className="bg-gray-700 text-white p-2 rounded-md ml-2"
              >
                <option value="">Wähle eine Fähigkeit</option>
                {availableAbilities
                  .filter(
                    (fa) =>
                      String(fa.class_id) === selectedClass &&
                      fa.category === selectedType
                  )
                  .map((fa) => (
                    <option key={fa.id} value={fa.id}>
                      {fa.name}
                    </option>
                  ))}
              </select>
            )}

            {/* 4) Stufe */}
            {selectedAbility && abilityStages.length > 0 && (
              <select
                value={selectedStage}
                onChange={(e) => setSelectedStage(e.target.value)}
                className="bg-gray-700 text-white p-2 rounded-md ml-2"
              >
                <option value="">Wähle eine Stufe</option>
                {abilityStages.map((st) => (
                  <option key={st.id} value={st.stage}>
                    {st.stage}
                  </option>
                ))}
              </select>
            )}

            {/* 5) Button speichern */}
            {selectedStage && (
              <button
                onClick={handleAddAbility}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
              >
                Fähigkeit hinzufügen
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MyCharacters;
