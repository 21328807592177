// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import AuthPage from './pages/AuthPage';
import Dashboard from './pages/Dashboard';
import Welcome from './pages/Welcome';
import CreateCharacter from './pages/CreateCharacter';
import MyCharacters from './pages/MyCharacters';
import Login from './Login'; // Importiere die Login-Seite
import ForceAbilitiesLibrary from './pages/ForceAbilitiesLibrary'; // Importiere die ForceAbilitiesLibrary


function App() {
  const isLoggedIn = !!localStorage.getItem('token'); // Beispiel für eine Login-Überprüfung

  return (
    <Router>
      {isLoggedIn && <Header />}
      <Routes>
        <Route path="/" element={<AuthPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <AuthPage />} />
        <Route path="/create-character" element={isLoggedIn ? <CreateCharacter /> : <AuthPage />} />
        <Route path="/my-characters" element={isLoggedIn ? <MyCharacters /> : <AuthPage />} />
        <Route path="/force-abilities" element={isLoggedIn ? <ForceAbilitiesLibrary /> : <AuthPage />} /> {/* Route für Fähigkeiten */}
        <Route path="/welcome" element={isLoggedIn ? <Welcome /> : <AuthPage />} />
      </Routes>
    </Router>
  );
}

export default App;