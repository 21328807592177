import React, { useState } from 'react';
import axios from 'axios';

function Login({ onSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/login', {
        username,
        password,
      });
      if (response.status === 200) {
        const user = response.data.user;

        // Token und Benutzer-ID im Local Storage speichern
        localStorage.setItem('token', 'dummyToken123');
        localStorage.setItem('userId', user.id);
        console.log('Login erfolgreich, Token und Benutzer-ID gespeichert:', user.id);

        if (onSuccess) {
          onSuccess(); // Erfolgreicher Login-Callback
        }

        // Seite neu laden, um Zustand zu aktualisieren
        window.location.reload();
      }
    } catch (error) {
      alert('Login fehlgeschlagen');
    }
  };

  return (
    <form onSubmit={handleLogin} className="space-y-4">
      <div>
        <label className="block mb-2">Benutzername:</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
          required
        />
      </div>

      <div>
        <label className="block mb-2">Passwort:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
          required
        />
      </div>

      <button
        type="submit"
        className="mt-6 w-full bg-gradient-to-r from-red-500 to-purple-500 text-white py-3 px-4 rounded-md hover:bg-purple-700 transition"
      >
        Login
      </button>
    </form>
  );
}

export default Login;