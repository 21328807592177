import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Register from '../Register';
import Login from '../Login';

function AuthPage() {
  const [isLogin, setIsLogin] = useState(true); // True für Login, false für Registrierung
  const navigate = useNavigate(); // Navigation-Funktion für Weiterleitung

  // Funktion zur Umschaltung zwischen Login und Registrierung
  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  // Erfolgreicher Login/Registrierung (setzt Pfad je nach Status)
  const handleSuccess = () => {
    if (isLogin) {
      navigate('/dashboard'); // Wenn Login erfolgreich, zum Dashboard
    } else {
      navigate('/welcome'); // Wenn Registrierung erfolgreich, zur Willkommensseite
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-md shadow-md max-w-md w-full text-white">
        <h2 className="text-2xl font-bold mb-6 text-center">{isLogin ? 'Login' : 'Registrierung'}</h2>

        {isLogin ? <Login onSuccess={handleSuccess} /> : <Register onSuccess={handleSuccess} />}

        <button
          onClick={toggleForm}
          className="mt-6 w-full bg-gradient-to-r from-red-500 via-orange-500 via-yellow-500 via-green-500 via-blue-500 via-indigo-500 to-purple-500 text-white py-3 px-4 rounded-md hover:bg-purple-700 transition"
        >
          {isLogin ? 'Zur Registrierung wechseln' : 'Zum Login wechseln'}
        </button>
		
      </div>
    </div>
  );
}

export default AuthPage;