import React from 'react';
import axios from 'axios';

class ForceAbilitiesLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      abilities: [],
      filters: {
        abilityClass: '',
        category: '',
      },
      classes: [],
      categories: [],
      selectedAbility: null,
      stages: [],
    };
  }

  componentDidMount() {
    this.fetchClasses();
    this.fetchCategories();
    this.fetchAbilities();
  }

  fetchClasses = async () => {
    try {
      const response = await axios.get('http://localhost:5000/force_classes');
      if (response.status === 200) {
        this.setState({ classes: response.data });
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Klassen:', error);
    }
  };

  fetchCategories = async () => {
    try {
      const response = await axios.get('http://localhost:5000/force_abilities/categories');
      if (response.status === 200) {
        this.setState({ categories: response.data });
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Kategorien:', error);
    }
  };

  fetchAbilities = async () => {
    try {
      const response = await axios.get('http://localhost:5000/force_abilities');
      if (response.status === 200) {
        this.setState({ abilities: response.data });
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Fähigkeiten:', error);
    }
  };

  applyFilters = async () => {
    try {
      const response = await axios.get('http://localhost:5000/force_abilities/filter', {
        params: this.state.filters,
      });
      if (response.status === 200) {
        this.setState({ abilities: response.data });
      }
    } catch (error) {
      console.error('Fehler beim Filtern der Fähigkeiten:', error);
    }
  };

  handleFilterChange = async (e) => {
    const { name, value } = e.target;

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          [name]: value,
        },
      }),
      async () => {
        if (name === 'abilityClass' && value) {
          try {
            const response = await axios.get('http://localhost:5000/force_abilities/categories', {
              params: { className: value },
            });
            if (response.status === 200) {
              this.setState({ categories: response.data });
            }
          } catch (error) {
            console.error('Fehler beim Abrufen der Kategorien:', error);
          }
        }
      }
    );
  };

  handleAbilityClick = async (ability) => {
    this.setState({ selectedAbility: ability });
    try {
      const response = await axios.get(`http://localhost:5000/force_abilities/${ability.id}/stages`);
      if (response.status === 200) {
        console.log('Stufen geladen:', response.data); // Debugging hinzufügen
        this.setState({ stages: response.data });
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Stufen:', error);
    }
  };

  handleCardClick = (index) => {
    this.setState((prevState) => {
      const newStages = [...prevState.stages];
      newStages[index].flipped = !newStages[index].flipped;
      return { stages: newStages };
    });
  };

  render() {
    const { abilities, classes, categories, selectedAbility, stages } = this.state;

    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
        <h2 className="text-2xl font-bold mb-6">Fähigkeitenbibliothek des Dunklen Ordens</h2>

        <div className="flex mb-6">
          <select
            name="abilityClass"
            onChange={this.handleFilterChange}
            className="p-2 mr-4 bg-gray-700 text-white"
          >
            <option value="">Klasse wählen</option>
            {classes.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>

          <select
            name="category"
            onChange={this.handleFilterChange}
            className="p-2 mr-4 bg-gray-700 text-white"
          >
            <option value="">Kategorie wählen</option>
            {categories.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>

          <button onClick={this.applyFilters} className="bg-purple-600 py-2 px-4 rounded hover:bg-purple-700">
            Filter anwenden
          </button>
        </div>

        {abilities.length > 0 ? (
          <table className="table-auto w-full max-w-4xl bg-gray-800 text-left text-sm">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b border-gray-700">Name</th>
                <th className="px-4 py-2 border-b border-gray-700">Klasse</th>
                <th className="px-4 py-2 border-b border-gray-700">Kategorie</th>
                <th className="px-4 py-2 border-b border-gray-700">Beschreibung</th>
              </tr>
            </thead>
            <tbody>
              {abilities.map((ability) => (
                <tr
                  key={ability.id}
                  onClick={() => this.handleAbilityClick(ability)}
                  className="cursor-pointer hover:bg-gray-700"
                >
                  <td className="px-4 py-2 border-b border-gray-700">{ability.name}</td>
                  <td className="px-4 py-2 border-b border-gray-700">{ability.class_name}</td>
                  <td className="px-4 py-2 border-b border-gray-700">{ability.category}</td>
                  <td className="px-4 py-2 border-b border-gray-700">{ability.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-lg">Keine Fähigkeiten vorhanden.</p>
        )}

        {selectedAbility && (
		  <div className="mt-8 w-full max-w-4xl">
			<h3 className="text-xl font-bold mb-4">{selectedAbility.name} - Stufen</h3>
			<div className="grid grid-cols-5 gap-6">
			  {stages.length > 0 ? (
				stages.map((stage, index) => (
				  <div
					key={index}
					className={`flip-card w-60 h-48 bg-gray-700 text-white rounded-lg cursor-pointer transform transition-transform duration-500 hover:z-10 ${
					  stage.flipped ? 'rotate-y-180' : ''
					}`}
					onClick={() => this.handleCardClick(index)}
				  >
					<div className="flip-card-inner relative w-full h-full">
					  <div className={`flip-card-front absolute w-full h-full flex items-center justify-center bg-gray-700 rounded-lg p-4`}>
						Stufe {stage.stage}
					  </div>
					  <div className={`flip-card-back absolute w-full h-full flex items-center justify-center bg-gray-500 rounded-lg p-4`}>
						{stage.description}
					  </div>
					</div>
				  </div>
				))
			  ) : (
				<p>Keine Stufen vorhanden</p>
		)}
    </div>
  </div>
)}
      </div>
    );
  }
}

export default ForceAbilitiesLibrary;