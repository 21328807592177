import React from 'react';

function Welcome() {
  return (
    <div className="welcome">
      <h1>Willkommen in der Anwendung!</h1>
      <p>Du hast dich erfolgreich angemeldet.</p>
	  <p>Ein Administrator wird deinen Account freischalten. Bitte gedulde dich etwas.</p>
    </div>
  );
}

export default Welcome;