import React, { useState } from 'react';
import axios from 'axios';

function CreateCharacter() {
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [birthPlanet, setBirthPlanet] = useState('');
  const [unit, setUnit] = useState('');
  const [position, setPosition] = useState('');
  const [rank, setRank] = useState('');

    // Benutzer-ID aus Local Storage holen (wichtig: nur eine Quelle)
  const userId = localStorage.getItem('userId');

  const handleCreateCharacter = async (e) => {
    e.preventDefault();

    if (!userId) {
      console.error('User-ID nicht gefunden');
      alert('Fehler: Benutzer nicht eingeloggt');
      return;
    }

    try {
      const response = await axios.post('http://localhost:5000/characters/create', {
        userId,
        name,
        firstName,
        birthDate,
        birthPlanet,
        unit,
        position,
        rank,
      });
      if (response.status === 201) {
        alert('Charakter erfolgreich erstellt');
        // Felder zurücksetzen
        setName('');
        setFirstName('');
        setBirthDate('');
        setBirthPlanet('');
        setUnit('');
        setPosition('');
        setRank('');
      }
    } catch (error) {
      console.error('Fehler beim Erstellen des Charakters:', error);
      alert('Fehler beim Erstellen des Charakters');
    }
  };


  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
      <form onSubmit={handleCreateCharacter} className="bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-lg space-y-6">
        <h2 className="text-2xl font-bold mb-6 text-center">Charakter erstellen</h2>
        
        <div>
          <label className="block mb-2">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
            required
          />
        </div>
        <div>
          <label className="block mb-2">Vorname:</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
            required
          />
        </div>
        <div>
          <label className="block mb-2">Geburtsdatum (ddmmyy n.E. / v.E.):</label>
          <input
            type="text"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
            required
          />
        </div>
        <div>
          <label className="block mb-2">Geburtsplanet:</label>
          <input
            type="text"
            value={birthPlanet}
            onChange={(e) => setBirthPlanet(e.target.value)}
            className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
            required
          />
        </div>
        <div>
          <label className="block mb-2">Einheit:</label>
          <input
            type="text"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
            className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
          />
        </div>
        <div>
          <label className="block mb-2">Posten:</label>
          <input
            type="text"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
          />
        </div>
        <div>
          <label className="block mb-2">Rang:</label>
          <input
            type="text"
            value={rank}
            onChange={(e) => setRank(e.target.value)}
            className="w-full p-3 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-600"
          />
        </div>

        <button
          type="submit"
          className="mt-6 w-full bg-gradient-to-r from-red-500 via-orange-500 via-yellow-500 via-green-500 via-blue-500 via-indigo-500 to-purple-500 text-white py-3 px-4 rounded-md hover:bg-purple-700 transition"
        >
          Charakter erstellen
        </button>
      </form>
    </div>
  );
}

export default CreateCharacter;